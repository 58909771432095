@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/scss/fonts.scss";
// sfa
@import url('https://fonts.googleapis.com/css2?family=Spline+Sans&display=swap');

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Inter-Regular", Arial, sans-serif;
  font-size: 16px;
  color: #000000;
}

.rti--container{

  --rti-bg: #fff;
  --rti-border: #181F48 !important;
  --rti-radius:10px !important;
 
  .-rti-border{
    border: #000000;
  }

  .rti--input{
    width: 20% !important;
     padding-top: 7px;
     padding-bottom: 6px;
    font-weight: 100px !important;
    font-size: 12px;
  }
  .rti--input:focus{
    box-shadow: none !important;
  }

}
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
    border-radius: 0 90px 90px 0;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 90px;
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;  
} 

.Toastify{
  .Toastify__toast-container{
      width: 700px;
  }
}

.star-app{
  .star-svg{
    display: inline-block;
  }
}

.swagger_wrapper{
    .information-container{
      display: none;
    }

    .opblock.opblock-get{
      border: 1px solid rgba(0, 0, 0, 0.5) !important;
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    }   
    
    .opblock.opblock-post {
      border: 1px solid rgba(0, 0, 0, 0.5) !important;
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  }
}

.notification-list-inner {
  width: 380px;
  display: inline-block;
  background-color: #FFFFFF;
  border: 0.2px solid #8223d2;
  box-shadow: 4px 4px 11px 3px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 20px 16px 10px 16px;
  position: relative;
  top: 0px;
  right: 175px;
  &.progress-list-inner {
    width: 250px;
  }
  &::before {
    content: '';
    display: none;
    position: absolute;
    width: 24px;
    height: 20px;
    background-color: #fff;
    top: -10px;
    z-index: 0;
    right: 35px;
    transform: rotate(40deg);
    border: 1px solid #ccc;
    border-bottom-width: 0;
    border-right-width: 0;
  }
  .heading{
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
    color: #000000;
    margin-bottom: 25px;
  }
  .cancelIcon{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  ul {
    list-style-type: none;
    padding: 0;
    overflow: auto;
    height: 200px;
    margin-bottom: 0;
    li {
      word-break: break-all;
      
      .flr {
        float: right;
      }
      .mgtop12 {
        margin-top: 12px;
      }

      .messageWrapper{
          padding: 10px;
          background: #EFF3FF;
          backdrop-filter: blur(40px);
          border-radius: 12px;
          margin-bottom: 16px;

          .content{
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: #000000;
          }

          .txtLink{
            font-size: 10px;
            font-weight: 400;            
            line-height: 18px;
            color: #316AFF;
          }
          .rightContent{
            font-size: 10px;
            font-weight: 400;            
            line-height: 18px;
            text-align: right;
            color: #000000;
          }
      }

    }
  }  
}

.logoutPoperWrapper {
  width: 110px;
  max-height: 45px;
  padding: 0px 0px 0px 20px;
  right: 45px;
  top: 4px;
}

.header{
  .arrow-wrapper{
    .downArrow {
      cursor: pointer;
    }
    .upArrow {
      cursor: pointer;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }  
}
.loginBg {
  background-image: url('../public/images/bgLoginImg.svg');
  background-repeat: no-repeat;
  background-size: 100vw 80vh;
  margin: 2% 0px;
  min-height: 80vh;
}

[data-amplify-authenticator] [data-amplify-container] {
  margin: 6%;
  width: auto !important;
}

.amplify-tabs {
  display: none;
}
.amplify-button--primary {
  background: #8223d2 !important;
}
.amplify-button--small {
  color: #8223d2 !important;
}
.multiSelectContainer {
  width: 100%;
  // padding: 5px !important;
  border: 1px #8223d2 solid !important;
  border-radius: 6px;
  background: white;
}