@font-face {
    font-family: 'Inter-Light';
    src: url('/assets/fonts/Inter-Light.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: 'arial';
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('/assets/fonts/Inter-Medium.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: 'arial';
}

@font-face {
    font-family: 'Inter-Regular';
    src: url('/assets/fonts/Inter-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: 'arial';
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('/assets/fonts/Inter-SemiBold.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: 'arial'
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('/assets/fonts/Inter-Bold.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: 'arial'
}